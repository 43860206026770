/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  env: {
    STAGE: "prod",
    PRODUCT: "tax",
    DOMAIN: "beta.canei.io",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://kt08oepnw6.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://kt08oepnw6.execute-api.eu-central-1.amazonaws.com/prod/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_YITJdGpv9",
    APP_CLIENT_ID: "645gse2c5bnccphpil6ne4t07d",
    IDENTITY_POOL_ID: "eu-central-1:08e4e3dc-85c5-4273-bee1-dccc194f1bf6",
  },
  i18n: {
    host: "locales.digital.beta.canei.io",
  },
  assets: {
    host: "assets.digital.beta.canei.io",
  },
  registration: {
    FORM_POST: "https://support.digital.beta.canei.io/v1/forms",
    NEXT_PAGE: "https://app.tax.beta.canei.io",
    TO_REFERRER: "https://canei.tax/register-success/",
  },
  payment: {
    publicKey: "pk_test_51Bo6NuEjjbFVZjn4S20X6Zjiu7W3kF7p2Cla974kKnAo5zowGaIxALu07W97xWFzyqcNvtLWAvno9ADlYegPApqI001Lc5dWNC",
  },
};

export default awsConfig;
